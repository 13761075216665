import { Helmet } from 'react-helmet-async'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getApplications, editApplication } from '../api/applications'

//MUI
import { Paper, Button, Stack, Alert } from '@mui/material'

//Components
import { generateApplicationsColumns } from '../components/Application/List/columns'
import ApplicationCreationDrawer from '../components/Application/Create/Drawer'
import LinkServiceModal from '../components/Application/List/LinkServiceModal'
import DeleteApplicationModal from '../components/Application/List/DeleteApplicationModal'

//UI
import Title from 'components/UI/Title'
import DataTable from 'components/UI/DataTable'
import ApplicationEditingDrawer from '../components/Application/Edit/Drawer'
import { useAuth } from 'contexts/AuthProvider'

const ApplicationsListPage = () => {
  const { currentUser } = useAuth();
  const { setSnackbar } = useApp()
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [deletingApplication, setDeletingApplication] = useState(null)
  const [editingApplication, setEditingApplication] = useState(null)
  const [selectedApplications, setSelectedApplications] = useState([])

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchData = async () => {
  
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getApplications({
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.error(e);
      }
    }

    fetchData()
  }, [pageState.page, pageState.pageSize])

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const openDeleteModal = (application) => {
    setDeletingApplication(application)
  }

  const openEditModal = (application) => {
    setEditingApplication(application)
  }

  const handleCreatedApplication = (response) => {
    setPageState((old) => ({
      ...old,
      data: [response?.application, ...pageState.data],
    }))

    setIsDrawerOpen(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleEditedApplication = (response, application) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((app) => {
        if (app.id === response?.application.id) {
          let responseApplication = response?.application
          responseApplication.revisions_count = application.revisions_count

          return responseApplication
        }

        return app
      }),
    }))

    setIsDrawerOpen(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleDeletedApplication = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((application) => application.id !== deletingApplication?.id),
    }))

    setDeletingApplication(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const processRowUpdate = useCallback(
    async (newRow, oldRow) => {
      const response = await editApplication(newRow.id, { name: newRow.name })

      setSnackbar({ children: response?.message, severity: 'success' })

      const applications = [...pageState.data]

      applications[applications.findIndex((application) => application.id === newRow.id)] = newRow

      setPageState((old) => ({
        ...old,
        data: applications,
      }))

      return response?.application
    },
    [pageState.data, setSnackbar]
  )

  const handleRowClick = (params) => {
    navigate(`/panel/applications/${params.id}`)
  }

  const handleLinkedService = (response, applications) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((application) => {
        if (applications.includes(application.id)) {
          let editApplication = application
          editApplication.is_linked = true
          editApplication.services.push(editApplication.services[0])
          return editApplication
        }

        return application
      }),
    }))

    setIsLinkModalOpen(false)
    setSelectedApplications([])
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const applicationsWithoutService = pageState.data.filter((application) => !application.is_linked)

  return (
    <>
      <Helmet title="Applications" />

      <Stack spacing={2} direction="column">
        {applicationsWithoutService.length > 0 && (
          <Alert variant="filled" severity="warning">
            {applicationsWithoutService.length > 1
              ? `To start using your apps, please link them to a service`
              : `To start using ${
                  applicationsWithoutService[0].name || applicationsWithoutService[0].mac_address
                }, please link it to a service`}
          </Alert>
        )}

        <Paper sx={{ p: 2 }}>
          <Title label="Applications">
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
                New application
              </Button>

              <Button
                variant="contained"
                onClick={() => setIsLinkModalOpen(true)}
                disabled={!selectedApplications.length}
              >
                Link to a service
              </Button>
            </Stack>
          </Title>

          <DataTable
            loading={pageState.isLoading}
            columns={generateApplicationsColumns(openDeleteModal, openEditModal, currentUser)}
            rows={pageState.data}
            rowCount={pageState.total}
            onPaginationModelChange={(model) => {
              setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
            }}
            disableColumnFilter={true}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedApplications(newRowSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            onRowClick={handleRowClick}
          />
        </Paper>

        <ApplicationCreationDrawer
          isOpen={isDrawerOpen}
          handleClose={handleDrawerClose}
          callback={handleCreatedApplication}
        />

        <ApplicationEditingDrawer
          isOpen={!!editingApplication}
          handleClose={() => setEditingApplication(null)}
          application={editingApplication}
          callback={handleEditedApplication}
        />

        <DeleteApplicationModal
          isOpen={!!deletingApplication}
          handleClose={() => setDeletingApplication(null)}
          application={deletingApplication}
          callback={handleDeletedApplication}
        />

        <LinkServiceModal
          isOpen={isLinkModalOpen}
          applications={selectedApplications}
          handleClose={() => setIsLinkModalOpen(false)}
          callback={handleLinkedService}
        />
      </Stack>
    </>
  )
}

export default ApplicationsListPage
