import { useState } from 'react'
import { useForm } from 'react-hook-form'

//API
import { shareApplication } from 'features/applications/api/applications'

//MUI
import { Drawer, Box, Typography, Alert, TextField, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const ShareApplicationDrawer = ({ applicationId, isOpen, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)
  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data) => {
    setGeneralError(null)
    setIsLoading(true)

    try {
      const response = await shareApplication(applicationId, data)

      reset()
      callback(response)
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e?.response?.data?.errors

        if (errors && errors['email']) {
          setError('email', { type: 'api', message: errors['email'] })
        }
      } else {
        console.log(e)
        setGeneralError(e?.response?.data?.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: 450, p: 4 }}>
        <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
          <div>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Share the application
            </Typography>

            {generalError && (
              <Alert severity="error" sx={{ my: 1 }}>
                {generalError}
              </Alert>
            )}
            <TextField
              label="Email"
              type="email"
              margin="normal"
              fullWidth
              required
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={errors.email ? errors.email?.message : ''}
            />
          </div>

          <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{ my: 1 }}
              fullWidth
            >
              Share
            </LoadingButton>

            <Button variant="text" fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default ShareApplicationDrawer
