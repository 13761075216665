//MUI
import { IconButton, Tooltip } from '@mui/material'

//Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

export const generateApplicationSharesColumns = ({ openDeleteModal }) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      flex: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 150,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={(event) => {
                  event.stopPropagation()
                  openDeleteModal(params.row)
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]
}
