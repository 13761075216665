import { useState, useEffect, useRef, useMemo } from 'react'

// Contexts
import { useApp } from 'contexts/AppProvider'

// API
import { getDeviceApplicationLogs } from 'features/devices/api/logs'

// MUI
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

const logsPerPage = 100

const LinkedApplications = ({ device, pageState, setPageState }) => {
  const { setSnackbar } = useApp()
  const logContainerRef = useRef(null)

  const [applicationLogs, setApplicationLogs] = useState([])
  const [activeApplication, setActiveApplication] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [page, setPage] = useState(0)

  
  const isInitialLoad = useRef(true)
  const applications = useMemo(() => device?.applications ?? [], [device])

  useEffect(() => {
    if (logContainerRef.current && page === 0) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
    }
  }, [applicationLogs, page])

  const handleTabChange = (event, newValue) => {
    setActiveApplication(newValue)
    setPage(0)
    setApplicationLogs([])
  }

  useEffect(() => {
    if (activeApplication !== null && applications[activeApplication]?.id) {
      fetchLogs(applications[activeApplication].id, 0)
    }
  }, [activeApplication])

  const fetchLogs = async (applicationId, pageNumber) => {
    try {
      if (pageNumber === 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))
      } else {
        setButtonLoading(true)
      }

      const result = await getDeviceApplicationLogs(device.id, applicationId, {
        page: pageNumber,
        logsPerPage: logsPerPage,
      })

      setApplicationLogs(() => [...(result?.logs?.slice().reverse() ?? []), ...applicationLogs])

      setPageState((old) => ({
        ...old,
        data: result?.data,
        total: result?.total,
      }))

      if (isInitialLoad.current) {
        // Scroll to the bottom only on the first load
        logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
        isInitialLoad.current = false
      }
    } catch (e) {
      console.error(e)
      setSnackbar({
        message: 'Failed to fetch logs.',
        severity: 'error',
      })
    } finally {
      if (pageNumber === 0) {
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }))
      } else {
        setButtonLoading(false)
      }
    }
  }

  const getEarlierLogs = () => {
    const nextPage = page + 1
    setPage(nextPage)
    if (applications[activeApplication]?.id) {
      fetchLogs(applications[activeApplication].id, nextPage)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeApplication ?? false}
        onChange={handleTabChange}
        aria-label="Application tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {applications.map((application, index) => (
          <Tab key={index} label={application.name} />
        ))}
      </Tabs>
      <Box
        ref={logContainerRef}
        sx={{
          bgcolor: '#1e1e1e',
          color: '#fefefe',
          fontFamily: 'monospace',
          padding: 2,
          borderRadius: 1,
          maxHeight: '65vh',
          overflowY: 'auto',
          mt: 2,
        }}
      >
        {applications.length === 0 ? ( // Check if no tab is selected
          <Typography>There are no applications linked to this device</Typography>
        ) : activeApplication === null || activeApplication === false ? (
          <Typography>Please select a tab to view the logs.</Typography>
        ) : pageState.isLoading ? (
          <Typography>Loading logs...</Typography>
        ) : (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
              onClick={() => getEarlierLogs()}
              loading={buttonLoading}
            >
              Load Earlier Logs
            </LoadingButton>
            {applicationLogs.map((log, index) => (
              <Typography key={index} sx={{ whiteSpace: 'pre-wrap', mb: 0.5 }}>
                {new Date(log.time * 1000).toLocaleString('en-GB')} - {log.log_message}
              </Typography>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

export default LinkedApplications
