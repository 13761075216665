import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// API
import { getApplicationShares } from 'features/applications/api/applications'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Components
import ShareApplicationDrawer from '../Create/Drawer'
import { generateApplicationSharesColumns } from './columns'
import DeleteShareModal from '../Edit/deleteShareModal'

//UI
import DataTable from 'components/UI/DataTable'

const ApplicationSharings = ({ isDrawerOpen, handleDrawerClose }) => {
  const { id: applicationId } = useParams()
  const { setSnackbar } = useApp()

  const [deletingShare, setDeletingShare] = useState(null)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchSharing = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getApplicationShares(applicationId, {
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data || [],
          total: result?.total || 0,
          page: result?.current_page || 1,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchSharing()
  }, [pageState.page, pageState.pageSize, applicationId])

  const handleShare = (response) => {
    setPageState((old) => ({
      ...old,
      data: [...old.data, response?.user],
      total: old.total + 1,
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const openDeleteModal = (user) => {
    setDeletingShare(user)
  }

  const handleDeleteShare = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((user) => user.id !== deletingShare?.id),
    }))

    setDeletingShare(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <DataTable
        loading={pageState.isLoading}
        columns={generateApplicationSharesColumns({ openDeleteModal })}
        rows={pageState.data}
        rowCount={pageState.total}
        onPaginationModelChange={(model) => {
          setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
        }}
        disableColumnFilter={true}
        disableSelectionOnClick={true}
      />

      <ShareApplicationDrawer
        applicationId={applicationId}
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        callback={handleShare}
      />

      <DeleteShareModal
        isOpen={deletingShare}
        handleClose={() => setDeletingShare(null)}
        user={deletingShare}
        applicationId={applicationId}
        callback={handleDeleteShare}
      />
    </>
  )
}

export default ApplicationSharings
