import { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate } from 'react-router-dom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getApplication } from '../api/applications'

//MUI
import { Paper, Stack, Button, Tabs, Tab } from '@mui/material'

//Components
import ApplicationRevisions from '../components/Application/View/Revision/List/list'
import LinkedServices from '../components/Application/View/LinkedServices/list'
import LinkServiceModal from '../components/Application/List/LinkServiceModal'
import ApplicationSharings from '../components/Application/View/Shares/List/ApplicationSharingsList'

//UI
import Title from 'components/UI/Title'
import { useAuth } from 'contexts/AuthProvider'

const ApplicationsViewPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { setIsBackdropOpen, setSnackbar } = useApp()
  const { currentUser } = useAuth()

  const [application, setApplication] = useState(null)
  const [drawerMode, setDrawerMode] = useState()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  const isOwned = useMemo(() => {
    if (!application) return

    return currentUser?.organisations?.[0].id === application?.organisation_id
  }, [currentUser, application])

  const tabs = useMemo(() => {
    const items = [
      {
        key: 'linked-services',
        label: 'Linked services',
      },
    ]

    if (isOwned) {
      items.push(
        ...[
          {
            key: 'application-revisions',
            label: 'Revisions',
          },
          {
            key: 'application-sharing',
            label: 'Sharing',
          },
        ]
      )
    }

    return items
  }, [isOwned])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        setIsBackdropOpen(true)

        const result = await getApplication(id)

        setApplication(result)
      } catch (e) {
        //TODO: Check for not found and redirect back
        console.error(e);
      } finally {
        setIsBackdropOpen(false)
      }
    }

    fetchApplication()
  }, [id, setIsBackdropOpen, pageState])

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const handleLinkedService = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((application) => {
        application.is_linked = true

        return application
      }),
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <Helmet title={'Applications - ' + application?.name} />

      <Paper sx={{ p: 2 }}>
        <Title label={application?.name}>
          <Stack direction={'row'} spacing={2}>
            <Button onClick={() => navigate('/panel/applications')} variant="outlined">
              Back
            </Button>
            {activeTab === 0 && (
              <Button
                variant="contained"
                onClick={() => {
                  setDrawerMode('link-service')
                  handleDrawerOpen()
                }}
              >
                Link to a service
              </Button>
            )}

            {activeTab === 1 && isOwned && (
              <Button
                variant="contained"
                onClick={() => {
                  setDrawerMode('create-revision')
                  handleDrawerOpen()
                }}
              >
                New revision
              </Button>
            )}

            {activeTab === 2 && (
              <Button
                variant="contained"
                onClick={() => {
                  setDrawerMode('share-application')
                  handleDrawerOpen()
                }}
              >
                Share this Application
              </Button>
            )}
          </Stack>
        </Title>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Application tabs"
          centered
          sx={{ mb: 1 }}
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} id={tab.key} key={index} />
          ))}
        </Tabs>

        {activeTab === 0 && <LinkedServices application={application} />}

        {activeTab === 1 && isOwned && (
          <ApplicationRevisions
            applicationId={id}
            isDrawerOpen={isDrawerOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        )}

        {activeTab === 2 && (
          <ApplicationSharings
            isDrawerOpen={drawerMode === 'share-application' && isDrawerOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        )}

        <LinkServiceModal
          isOpen={drawerMode === 'link-service' && isDrawerOpen}
          applications={[id]}
          handleClose={handleDrawerClose}
          callback={handleLinkedService}
        />
      </Paper>
    </>
  )
}

export default ApplicationsViewPage
